import { Controller } from "stimulus";
import {
  showSpinnerDialog, closeSpinnerDialog
} from "../../../../shared/common/dialogs/spinner_dialog_controller";
import { subscribe, unsubscribe } from "@/controllers/helpers/pub_sub";

const SHOW_TAKE_INITIAL_PAYMENT_DIALOG_EVENT =
  "showTakeInitialPaymentDialogEvent";

export default class extends Controller {
  static targets = [
    "ipData",
    "error",
    "submitButton",
    "autopayAuthorization",
    "autopayAuthorizationError",
    "cardAutopayAuthorization",
    "paymentForm",
    "optionalPayment",
    "optionalPaymentTax"
  ]

  connect() {
    subscribe(SHOW_TAKE_INITIAL_PAYMENT_DIALOG_EVENT, this.showDialog);

    if (typeof(CreditCardServiceV2) === "undefined") {
      setTimeout(this.formLoadingErrorMessage, 1000);
      Rollbar.error("CreditCardServiceV2 is undefined.");
    } else {
      setTimeout(this.buildForm.bind(this), 1000);
    }
  }

  disconnect() {
    unsubscribe(SHOW_TAKE_INITIAL_PAYMENT_DIALOG_EVENT, this.showDialog);
  }

  showDialog = () => {
    if (!this.targetDialog.hasAttribute("open")) {
      this.targetDialog.showModal();
    }
  };

  formLoadingErrorMessage = () => {
    document.getElementById("form-loading-error").classList.remove("hidden");
  }

  buildForm = () => {
    const ipData = this.ipDataTarget.dataset;
    CreditCardServiceV2.form({
      onComplete: response => {
        this.logResponse(response, ipData.auditUrl);
        if (!response) {
          // prevent rapid resubmissions in the case of network issues
          setTimeout(this.reEnableSubmit(this.submitButtonTarget), 5000);
        } else if (response.status === "Success") {
          // If confirmation url is present, charge occurs on the backend
          if (ipData.confirmPaymentUrl.length > 0) {
            showSpinnerDialog(ipData.confirmationMessage);

            this.fetchThenRender(
              ipData.confirmPaymentUrl,
              this.targetDialog,
              "POST",
              response,
            );
          }
        } else if (
          response.code === "cvv_validation_failure_allow_retry" ||
          response.code === "address_validation_failure_allow_retry" ||
          this.preAuthorizationError(response)
        ) {
          CreditCardServiceV2.showAddressFields();
          this.toggleAutopayDisableAttr(false);
        } else {
          setTimeout(this.reEnableSubmit(this.submitButtonTarget), 2000);
          this.toggleAutopayDisableAttr(false);
        }
      },
      ...this.creditCardParams(ipData)
    });
  }

  creditCardParams = ipData => {
    let authTokenEndpoint = ipData.authTokenEndpoint;
    // Always charge the IP on the backend in the card_payments_controller.rb
    // This will set the amount in the auth token (auth_token_create.rb) to
    // always be 0 for the credit card form charge
    authTokenEndpoint+= "?has_optional_payment=true";

    return {
      auth_token_endpoint: authTokenEndpoint,
      respond_to: ipData.respondTo,
      applicant_name: ipData.applicantFullName,
      require_check_box_auth: ipData.requireCheckBoxAuth,
      require_check_box_auth_text: ipData.debitCardReqText,
      debit_card_required: ipData.debitCardRequired,
      debit_card_auth_text: ipData.debitCardReqText,
      ccs_endpoint: ipData.ccsEndpoint,
      submit_button_text: ipData.submitButtonText,
      iframe_css_base: ipData.ccnumFieldCss,
      submit_button_classes:
        ipData.buttonClasses + " " + ipData.submitButtonClasses,
      clear_button_classes: ipData.buttonClasses,
      address: ipData.address,
      city: ipData.city,
      state: ipData.state,
      zip: ipData.zip,
      iframe_width: "100%",
      iframe_height: "60px",
      payment_source: ipData.paymentSource,
      hide_fields: { address: true, city: true, state: true, zip: true },
      kount_enabled: true,
      payment_context: ipData.paymentContext,
      phone_number: ipData.phoneNumber,
      customer_email: ipData.customerEmail,
      invoice_total: ipData.invoiceTotal,
      debug: true,
      fake_ccs: ipData.fakeCcs,
      paymentAmount: ipData.paymentAmount,
      business_entity: ipData.businessEntity,
      country: ipData.country,
      currency_code: ipData.currencyCode
    };
  }

  reEnableSubmit = element => {
    element.classList.remove("disabled");
  }

  submitForm = async () => {
    if (this.isAutopayUnchecked) {
      this.addautopayAuthorizationError();
      return;
    }
    this.preSubmissionActions();
    await this.submitCreditCardForm();
  }

  preSubmissionActions = () => {
    this.submitButtonTarget.classList.add("disabled");
    this.removeautopayAuthorizationError();
    this.toggleAutopayDisableAttr(true);
  }

  submitCreditCardForm = async () => {
    try {
      const res = await fetch(
        this.submitButtonTarget.dataset.url,
        window.acima.fetchInit({ method: "POST" }),
      );
      const data = await res.json();

      if (data.success == false) {
        this.handleErrors([data.message]);
        this.toggleAutopayDisableAttr(false);
      } else if (data.new_lock == true) {
        if (this.hasCardAutopayAuthorizationTarget == true) {
          this.setCardAutopayAuthorization();
        }
        const result = CreditCardServiceV2.submitForm();
        if (Array.isArray(result)) {
          this.handleErrors(result);
          this.toggleAutopayDisableAttr(false);
          setTimeout(() => this.clearErrors(), 5000);
        }
      } else {
        this.handleErrors([data.message]);
        this.toggleAutopayDisableAttr(false);
        setTimeout(() => window.location.reload(), 60000);
      }
    } catch (e) {
      Rollbar.error(e);
    }
  }

  addautopayAuthorizationError = () => {
    this.autopayAuthorizationErrorTarget.classList.remove("hide");
  }

  toggleAutopayDisableAttr = value => {
    if (this.hasAutopayAuthorization) {
      this.autopayAuthorizationTarget.disabled = value;
    }

    if (this.hasCardAutopayAuthorizationTarget == true) {
      this.cardAutopayAuthorizationTarget.disabled = value;
    }
  }

  removeautopayAuthorizationError = () => {
    if (this.hasAutopayAuthorization) {
      this.autopayAuthorizationErrorTarget.classList.add("hide");
    }
  }

  setCardAutopayAuthorization = () => {
    /**
     * Persist the customers decision on form submission. CCS will respond in /process_service_response.rb
     * which handles updating the card_at column on applicant_autopay_enrollments to nil if CCS returns
     * a failure response (ie. unsuccessful payment)
     */
    if (this.cardAutopayAuthorizationTarget.checked == true) {
      this.cardAutopayAuthorizationTarget.disabled = true;
      const url = this.paymentFormTarget.dataset.url;
      const request = window.acima.fetchInit({ method: "PATCH" });
      fetch(url, request)
        .then(response => response.json())
        .then(jsonResponse => this.handleResponse(jsonResponse))
        .catch(err => console.error(err));
    }
  }

  handleResponse = jsonResponse => {
    if (!jsonResponse.success) {
      throw new Error(jsonResponse.message);
    }
  }

  handleErrors = data => {
    this.submitButtonTarget.classList.remove("disabled");
    this.displayErrors(data);
    this.requestUnlock();
  }

  logResponse = (response, auditUrl) => {
    var description = [];

    Object.keys(response).forEach(property => {
      description.push(property + ":" + response[property]);
    });

    var params = [
      "name=lease.initial_payment.service_response",
      "description=Initial payment service response " + description.join("|")
    ].join("&");
    var xhr = new XMLHttpRequest();
    xhr.open("POST", auditUrl);
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr.setRequestHeader("X-CSRF-Token", $("meta[name=\"csrf-token\"]").attr("content"));
    xhr.send(params);
  };

  preAuthorizationError = response => {
    return response.charge &&
    response.charge.pre_authorization &&
    (
      response.charge.pre_authorization.error_code === "cvv_validation_failure_allow_retry" ||
      response.charge.pre_authorization.error_code === "address_validation_failure_allow_retry"
    );
  }

  requestUnlock = () => {
    fetch(
      this.submitButtonTarget.dataset.url,
      window.acima.fetchInit({ method: "DELETE" }),
    );
  }

  displayErrors = errors => {
    const el = this.errorTarget;
    el.innerHTML = "<p>" + errors.join("</p><p>") + "</p>";
    el.classList.remove("hide");
  }

  clearErrors = () => {
    const el = this.errorTarget;
    el.innerHTML = "";
    el.classList.add("hide");
  }

  // Responsible for calling the card_payments_controller create action
  // params will be the response from CCS
  fetchThenRender(url, modalDiv, requestType = "GET", params = {}) {
    const request = { method: `${requestType}` };

    if (Object.keys(params).length === 0) {
      var requestUrl = url;
    } else {
      var queryParams = new URLSearchParams(params);
      var requestUrl = `${url}?${queryParams}`;
    }

    fetch(requestUrl, window.acima.fetchInit(request))
      .then(response => response.text())
      .then(text => modalDiv.innerHTML = text)
      .then(() => closeSpinnerDialog())
      .catch(err => console.error(err));
  }

  showLoading = () => {
    this.targetDialog.innerHTML = SPINNER_MARKUP;
  }

  get targetDialog() {
    return document.getElementById("take-initial-payment-modal");
  }

  get isAutopayUnchecked() {
    return this.hasAutopayAuthorization && !this.isAutopayAuthorizationChecked;
  }

  get hasAutopayAuthorization() {
    return this.hasAutopayAuthorizationTarget === true;
  }

  get isAutopayAuthorizationChecked() {
    return this.autopayAuthorizationTarget.checked;
  }
}
