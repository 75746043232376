import { Controller } from "stimulus";

/** Location filter for merchant contract fundings */
export default class extends Controller {
  static targets = ["hidden", "input"];

  connect(){
    document.addEventListener('autocomplete.change', () => {
      const changeEvent = new Event("change");
      this.hiddenTarget.dispatchEvent(changeEvent);
    });
  }
}
