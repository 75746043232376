import { Controller } from "stimulus"
import LineItem from "../merchants/invoice/line_item.js"
import LineItemManager from "../merchants/invoice/line_item_manager.js"
import lineItemConstants from "../merchants/invoice/line_item_json.js"

export default class extends Controller {
  static targets = [
    "lineItems",

    "modalTitle",
    "modalErrorCredit",
    "modalErrorLease",

    "itemDescription",
    "itemPriceLabel",
    "itemPrice",
    "itemTypeMerchandise",
    "itemTypeAdditionalServices",
    "productTypeCredit",
    "productTypeLease",
    "itemConditionTitle",
    "itemConditionNew",
    "itemConditionUsed",
    "itemConditionDamagedCheckbox",
    "itemDamageDescription",
    "itemDamageDescriptionForm",

    "deliveryDate",
    "merchantReferenceNumber",

    "deleteButton",
    "saveButton",

    "creditDescriptions",
    "creditInvoiceTotalAndTax",

    "leaseDescriptions",
    "leaseRetailerInvoiceTotal",
    "leaseProcessingFee",
    "leaseInitialPaymentChoice",
    "leaseInitialPaymentChoiceButtons",
    "leaseInitialPaymentAmount",
    "acimaLeaseAmount",
    "leaseRecurringPaymentNoChoice",
    "leaseRecurringPaymentChoice",
    "leaseRecurringPaymentChoiceButtons",
    "leaseRecurringPayment",
    "leaseTermsContainerOptions",
    "leaseInitialPaymentPlusTax",
    "merchantFeePercentage",
    "merchantFeeAmount",

    "preRentPurchaseTerm",
    "preRentBuyoutAmount",
    "remainingBuyoutAmountLabel",
    "remainingPreRentBuyoutAmount",
    "totalOfPayments",
    "totalOfRenewalPayments",
    "renewalPaymentAmount",
    "renewalPaymentFrequency",

    "formErrorCredit",
    "formErrorLease",
    "formErrorLeaseMinimum",
    "saveForm",
    "formData",
    "formCount",
    "confirmationBody",
    "editModalConfirmation",

    "leaseInvoiceSummaryBox",
    "creditInvoiceSummaryBox",
    "emptyLeaseBox",
    "emptyCreditBox",
    "additionalDetailsModalLink",

    "underwritingProgramID",

    // initial payment buttons
    "initialPayment1",
    "initialPayment2",
    "initialPayment3",

    // fees on initial payment buttons
    "initialPaymentFee1",
    "initialPaymentFee2",
    "initialPaymentFee3",

    // recurring payment buttons
    "recurringPayment1",
    "recurringPayment2",
    "recurringPayment3",

    // rental period on recurring payment buttons
    "recurringPaymentPeriod1",
    "recurringPaymentPeriod2",
    "recurringPaymentPeriod3",
  ]

  // commands executed on page load
  connect() {
    // Imported data
    // import constants to hash
    this.hash = lineItemConstants

    // imported from /views/merchants/itemized_invoices/components/_itemization.html.slim
    this.creditContract = this.lineItemsTarget.dataset.creditContract
    this.leaseContract = this.lineItemsTarget.dataset.leaseContract
    this.importDataRaw = this.lineItemsTarget.dataset.importData
    this.invoiceMode = this.lineItemsTarget.dataset.invoiceMode
    this.leaseInitialPaymentAmount = this.lineItemsTarget.dataset.leaseInitialPaymentAmount
    this.leaseMerchantFeePercentage = this.lineItemsTarget.dataset.leaseMerchantFeePercentage
    this.leaseId = this.lineItemsTarget.dataset.leaseId
    this.underwritingProgramId = this.lineItemsTarget.dataset.underwritingProgramId
    this.userType = this.lineItemsTarget.dataset.userType
    this.submittedUnderwritingId = this.underwritingProgramIDTarget.value
    if (this.hasMerchantReferenceNumberTarget) {
      if (this.merchantReferenceNumberTarget.value == null) {
        this.merchantReferenceNumberTarget.value = this.lineItemsTarget.dataset.retailnumber
      }
    }

    // Format imported data
    this.creditApprovedAmount = this.approvedAmount(this.creditContract)
    if (this.approvedAmount(this.leaseContract) != 0) {
      this.leaseApprovedAmount = Number(this.lineItemsTarget.dataset.leaseApprovedAmountWithMargin)
    }

    const today = new Date()
    this.maxDate = new Date(today.setDate(today.getDate() + Number(this.lineItemsTarget.dataset.maxDate))).setHours(0, 0, 0, 0)
    this.minDate = new Date(this.lineItemsTarget.dataset.minDate).setHours(0, 0, 0, 0)

    this.importData = JSON.parse(this.importDataRaw).map((jsonString) => {
      return JSON.parse(jsonString)
    })

    // Setup page
    this.setConfirmationBody()
    this.integrateImportedData()
    this.setUnderwritingCalc()
  }

  /// Setup Page functions
  // set disclosure paragraph above line items
  setConfirmationBody() {
    if (this.itemTypeCredit()) {
      this.confirmationBodyTarget.innerText = acima.i18n.t("itemized_invoices.confirmation.credit_body")
    } else {
      this.confirmationBodyTarget.innerText = acima.i18n.t("itemized_invoices.confirmation.lease_body")
    }
  }

  // on edit page import LineItems into LineItemManager
  integrateImportedData() {
    if (this.importData.length > 0) {
      this.importData.map((itemJson) => {
        LineItemManager.addItem(new LineItem(itemJson))
      })

      this.updateDOM()
    }
  }

  // on edit page get underwriting program selected
  setUnderwritingCalc() {
    // exit function if executed more than 1 second after page load
    if (this.invoiceMode === this.hash.edit) {
      let details = {}
      try {
        details = this.invoiceCalculations.find(item => item.underwriting_program_id === Number(this.submittedUnderwritingId))
      } catch {
        details = null
      }

      if (details === null || details === undefined) {
        return
      }

      // If Ip exists
      if (!this.leaseInitialPaymentChoiceTarget.classList.contains(this.hash.hidden)) {
        document.querySelector('[initial-payment="' + details.initial_payment_amount + '"]').click()
      }

      // If Rp exists
      if (!this.leaseRecurringPaymentChoiceTarget.classList.contains(this.hash.hidden)) {
        document.querySelector('[underwriting-program-id="' + details.underwriting_program_id + '"]').click()
      }

      this.invoiceMode = this.hash.new
    }
  }

  // format raw subcontracts to approved amount
  approvedAmount(contract) {
    if (contract != this.hash.contract_does_not_exist && contract != '') {
      return JSON.parse(contract)["amount_approved"]
    }
    return 0
  }

  /// Line item functions
  lineItemAttributes() {
    return {
      description: this.itemDescriptionTarget.value,
      price: Number(this.itemPriceTarget.value),
      item_type: this.getItemType(),
      consumer_product_type: this.getProductType(),
      condition: this.getItemConditon(),
      damage: this.itemDamageDescriptionTarget.value
    }
  }

  // get item type for lineItemAttributes()
  getItemType() {
    if (this.isSplitTicket()) {
      if (this.itemTypeMerchandiseTarget.checked) {
        return this.hash.merchandise
      }
      if (this.itemTypeAdditionalServicesTarget.checked) {
        return this.hash.additional_services
      }
    } else {
      return this.hash.no_item_type
    }
  }

  // get item condition for lineItemAttributes()
  getItemConditon() {
    if (this.itemConditionNewTarget.checked) {
      return this.hash.new
    }
    if (this.itemConditionUsedTarget.checked) {
      return this.hash.used
    }
    return this.hash.no_condition
  }

  // get product type for lineItemAttributes()
  getProductType() {
    if (this.isSplitTicket()) {
      if (this.productTypeCreditTarget.checked) {
        return this.hash.credit
      } else {
        return this.hash.lease
      }
    }
    if (this.itemTypeCredit()) {
      return this.hash.credit
    }
    return this.hash.lease
  }

  /// LineItemManager functions
  addLineItem() {
    if (this.validateModalFields()) {
      const item = new LineItem(this.lineItemAttributes())
      LineItemManager.addItem(item)
      this.updateDOM()
    }
  }

  updateLineItem() {
    const id = this.saveButtonTarget.dataset.id
    LineItemManager.updateItem(id, this.lineItemAttributes())
    this.updateDOM()
  }

  removeLineItem() {
    const id = this.deleteButtonTarget.dataset.id
    LineItemManager.removeById(id)
    this.updateDOM()
  }

  /// execution function to update the html to reflect LineItemManager
  updateDOM() {
    this.lineItemsTarget.innerHTML = LineItemManager.lineItems().map((item) => {
      return this.generateLineItemHTML(item)
    }).join("")
    this.updateInvoice()
  }

  /// Generate Line Item HTML
  generateLineItemHTML(item) {
    // Note: data-id needed at both a and img tag because its possible to click on the a tag and not the img tag
    if (this.isSplitTicket()) {
      // Split ticket line items
      return `
        <div class="row invoice-line-item flex-line-item">
          <div class="line-item-label line-item-${item.consumer_product_type}-label">${acima.i18n.t("itemized_invoices.line_item." + item.consumer_product_type)}</div>
          <div class="line-item-text line-item-description">${item.description}</div>
          <div class="col-xs-4 text-right line-item-text line-item-cost line-item-price">$${this.formatPrice(item.price)}</div>
          <div class="edit-line-item">
            <a class="btn btn-sm btn-default" data-id=${item.id} data-target="#itemized_invoice_modal" data-toggle="modal" id="edit-pencil-button" data-action="click->credit-invoice#setEditModal">
              <div class="fa-regular fa-pencil" data-id=${item.id}></div>
            </a >
          </div >
        </div >
        `
    } else {
      // normal ticket line items
      return `
          <div class="row invoice-line-item flex-line-item">
            <div class="line-item-text line-item-description">${item.description}</div>
            <div class="col-xs-4 text-right line-item-text line-item-cost line-item-price">$${this.formatPrice(item.price)}</div>
            <div class="edit-line-item">
              <a class="btn btn-sm btn-default" data-id=${item.id} data-target="#itemized_invoice_modal" data-toggle="modal" id="edit-pencil-button" data-action="click->credit-invoice#setEditModal">
                <div class="fa-regular fa-pencil" data-id=${item.id}></div>
              </a >
            </div >
          </div >
        `
    }
  }

  /// depending on what subcontract are valid run certain update functions
  updateInvoice() {
    if (this.isSplitTicket()) {
      this.updateCreditInvoice()
      this.updateLeaseInvoice()
    } else if (this.itemTypeCredit()) {
      this.updateCreditInvoice()
    } else {
      this.updateLeaseInvoice()
    }

    this.displayInvoiceBasedOnLineItems()
    this.enableSaveForm()
  }

  updateCreditInvoice() {
    this.creditDescriptionsTarget.innerText = LineItemManager.summaryCreditDescription()
    this.creditInvoiceTotalAndTaxTarget.innerText = "$" + this.formatPrice(LineItemManager.totalCreditPrice())
  }

  updateLeaseInvoice() {
    this.leaseDescriptionsTarget.innerText = LineItemManager.summaryLeaseDescription()
    this.leaseRetailerInvoiceTotalTarget.innerText = "$" + this.formatPrice(LineItemManager.totalLeasePrice())
    this.leaseRetailerInvoiceTotalTarget.value = accounting.toFixed(LineItemManager.totalLeasePrice(), 2)
    this.calculateLeaseSummaryAmounts()
  }

  // run toggles for summary boxes
  displayInvoiceBasedOnLineItems() {
    this.toggleLeaseBox()
    this.toggleCreditBox()
  }

  // hide/show lease summary box if lease line items detected
  toggleLeaseBox() {
    if (this.hasEmptyLeaseBoxTarget && this.hasLeaseInvoiceSummaryBoxTarget) {
      if (LineItemManager.leaseCount() > 0) {
        this.emptyLeaseBoxTarget.classList.add(this.hash.hidden)
        this.leaseInvoiceSummaryBoxTarget.classList.remove(this.hash.hidden)
        this.additionalDetailsModalLinkTarget.classList.remove(this.hash.hidden)
      } else {
        this.emptyLeaseBoxTarget.classList.remove(this.hash.hidden)
        this.leaseInvoiceSummaryBoxTarget.classList.add(this.hash.hidden)
        this.additionalDetailsModalLinkTarget.classList.add(this.hash.hidden)
      }
    }
  }

  // hide/show credit summary box if credit line items detected
  toggleCreditBox() {
    if (this.hasEmptyCreditBoxTarget && this.hasCreditInvoiceSummaryBoxTarget) {
      if (LineItemManager.creditCount() > 0) {
        this.emptyCreditBoxTarget.classList.add(this.hash.hidden)
        this.creditInvoiceSummaryBoxTarget.classList.remove(this.hash.hidden)
      } else {
        this.emptyCreditBoxTarget.classList.remove(this.hash.hidden)
        this.creditInvoiceSummaryBoxTarget.classList.add(this.hash.hidden)
      }
    }
  }

  /// Modal setup functions
  setNewModal() {
    this.modalTitleTarget.innerText = ""
    this.modalTitleTarget.innerText = acima.i18n.t("itemized_invoices.modal.add_item")
    this.setItemConditionTextLabel()
    this.clearModal()
    this.saveButtonTarget.dataset.action = "click->credit-invoice#addLineItem"
  }

  setEditModal(event) {
    this.modalTitleTarget.innerText = ""
    this.modalTitleTarget.innerText = acima.i18n.t("itemized_invoices.modal.edit_item")
    this.clearModal()
    this.deleteButtonTarget.classList.remove(this.hash.hidden)
    this.deleteButtonTarget.dataset.id = event.target.dataset.id
    this.saveButtonTarget.dataset.id = event.target.dataset.id
    this.saveButtonTarget.dataset.action = "click->credit-invoice#updateLineItem"
    this.populateModal()
  }

  clearModal() {
    if (this.isSplitTicket()) {
      this.itemTypeMerchandiseTarget.checked = false
      this.itemTypeAdditionalServicesTarget.checked = false
      this.productTypeCreditTarget.checked = false
      this.productTypeLeaseTarget.checked = false
      this.setItemConditionTextLabel()

      this.itemDamageDescriptionFormTarget.classList.add(this.hash.hidden)
    }

    this.itemDescriptionTarget.value = ""
    this.itemPriceTarget.value = ""
    this.setDefaultValues()
    this.itemConditionNewTarget.checked = false
    this.itemConditionUsedTarget.checked = false
    this.itemConditionDamagedCheckboxTarget.checked = false
    this.itemDamageDescriptionTarget.value = ""
    this.itemDamageDescriptionFormTarget.classList.add(this.hash.hidden)

    this.deleteButtonTarget.classList.add(this.hash.hidden)
    this.modalErrorCreditTarget.classList.add(this.hash.hidden)
    this.modalErrorLeaseTarget.classList.add(this.hash.hidden)
    this.saveButtonTarget.classList.add(this.hash.disabled)
  }

  setDefaultValues() {
    if (this.isSplitTicket()) {
      this.itemPriceLabelTarget.innerHTML = acima.i18n.t("itemized_invoices.modal.item_price")
      this.itemPriceTarget.placeholder = ""
    } else if (this.itemTypeCredit()) {
      this.itemPriceLabelTarget.innerHTML = acima.i18n.t("itemized_invoices.modal.item_price_including_tax")
      this.itemPriceTarget.placeholder = "1,348.09"
    } else {
      this.itemPriceLabelTarget.innerHTML = acima.i18n.t("itemized_invoices.modal.item_price_without_tax")
      this.itemPriceTarget.placeholder = "1,200.00"
    }
  }

  populateModal() {
    const item = LineItemManager.getById(this.saveButtonTarget.dataset.id)
    this.itemDescriptionTarget.value = item.description
    this.itemPriceTarget.value = item.price

    if (this.isSplitTicket()) {
      if (item.item_type === this.hash.merchandise) {
        this.itemTypeMerchandiseTarget.checked = true
      } else {
        this.itemTypeAdditionalServicesTarget.checked = true
      }

      if (item.consumer_product_type === this.hash.credit) {
        this.productTypeCreditTarget.checked = true
      }

      if (item.consumer_product_type === this.hash.lease) {
        this.productTypeLeaseTarget.checked = true
      }

      this.setItemConditionTextLabel()
    }

    if (item.condition === this.hash.new) {
      this.itemConditionNewTarget.checked = true
    } else if (item.condition === this.hash.used) {
      this.itemConditionUsedTarget.checked = true
    }

    if (this.hash.regex.test(item.damage) && item.damage != null) {
      this.itemConditionDamagedCheckboxTarget.checked = true
      this.itemDamageDescriptionTarget.value = item.damage
      this.itemDamageDescriptionFormTarget.classList.remove(this.hash.hidden)
    }

    this.saveButtonTarget.classList.remove(this.hash.disabled)
  }

  /// configure modal depending populated data
  // set item condition text
  setItemConditionTextLabel() {
    if ((this.isSplitTicket() && this.productTypeCreditTarget.checked && this.itemTypeAdditionalServicesTarget.checked) || (this.itemTypeCredit() && !this.itemTypeLease())) {
      this.itemConditionTitleTarget.innerText = ""
      this.itemConditionTitleTarget.innerText = acima.i18n.t("itemized_invoices.modal.item_condition_optional")
    } else {
      this.itemConditionTitleTarget.innerText = ""
      this.itemConditionTitleTarget.innerText = acima.i18n.t("itemized_invoices.modal.item_condition")
    }
  }

  // toggle item damage description box
  setItemConditionDamagedCheck(event) {
    if (event.target.checked) {
      this.itemDamageDescriptionFormTarget.classList.remove(this.hash.hidden)
    } else {
      this.itemDamageDescriptionFormTarget.classList.add(this.hash.hidden)
      this.itemDamageDescriptionTarget.value = ""
    }
  }

  // Export LineItemManager data to form for backend
  updateFormData() {
    this.formDataTarget.value = LineItemManager.exportItems()
    this.formCountTarget.value = LineItemManager.count()
  }

  // toggle Modal save button when modal is valid and execute setItemConditionTextLabel()
  displayTaxLabel() {
    if (this.productTypeCreditTarget.checked) {
      this.itemPriceLabelTarget.innerHTML = acima.i18n.t("itemized_invoices.modal.item_price_including_tax")
      this.itemPriceTarget.placeholder = "1,348.09"
    } else if (this.productTypeLeaseTarget.checked) {
      this.itemPriceLabelTarget.innerHTML = acima.i18n.t("itemized_invoices.modal.item_price_without_tax")
      this.itemPriceTarget.placeholder = "1,200.00"
    } else {
      this.itemPriceLabelTarget.innerHTML = acima.i18n.t("itemized_invoices.modal.item_price")
    }
  }

  enableSaveModal() {
    this.setItemConditionTextLabel()

    if (this.validateModalFields()) {
      this.saveButtonTarget.classList.remove(this.hash.disabled)
    } else {
      this.saveButtonTarget.classList.add(this.hash.disabled)
    }
  }

  // toggle Form save button when form is valid
  enableSaveForm() {
    if (this.validateForm()) {
      this.saveFormTarget.classList.remove(this.hash.disabled)
      this.updateFormData()
    } else {
      this.saveFormTarget.classList.add(this.hash.disabled)
    }
  }

  // display confirmation modal if saving edited invoice
  editInvoiceModal(e) {
    e.preventDefault()
    document.getElementById("confirmation_modal").classList.remove(this.hash.hidden)
  }

  // submit form to backend if valid
  submitForm(e) {
    e.preventDefault()
    this.saveFormTarget.classList.add(this.hash.disabled)
    this.editModalConfirmationTarget.classList.add(this.hash.disabled)
    if (this.validateForm()) {
      document.getElementById("inv_form").submit()
    } else {
      this.backToForm()
      this.saveFormTarget.classList.remove(this.hash.disabled)
      this.editModalConfirmationTarget.classList.remove(this.hash.disabled)
    }
  }

  // hide confirmation modal if visible
  backToForm() {
    document.getElementById("confirmation_modal").classList.add(this.hash.hidden)
  }

  /// Validations
  // validate Form
  validateForm() {
    let result = true
    if (!this.validateLineItem()) {
      result = false
    } else {
      result = this.validateTotalPrice()
        && this.validateDeliveryDate()
        && this.validateRetailerReferenceNumber()
        && this.validateUnderwritingProgram()
    }
    this.displayFormError()

    return result
  }

  validateLineItem() {
    return LineItemManager.count() > 0
  }

  validateCreditTotals() {
    if (LineItemManager.creditCount() === 0) {
      return true
    } else {
      return LineItemManager.totalCreditPrice() <= this.creditApprovedAmount
        && LineItemManager.totalCreditPrice() >= 0
    }
  }

  validateLeaseTotals() {
    if (LineItemManager.leaseCount() === 0) {
      return true
    } else {
      return (LineItemManager.totalLeasePrice() <= this.leaseApprovedAmount
        && LineItemManager.totalLeasePrice() >= 100)
    }
  }

  validateTotalPrice() {
    return this.validateCreditTotals() && this.validateLeaseTotals()
  }

  validateDeliveryDate() {
    if (this.deliveryDateTarget.value.length === 0) {
      return false
    } else {
      const deliveryDate = Date.parse(this.deliveryDateTarget.value)
      return this.minDate <= deliveryDate
        && deliveryDate <= this.maxDate
    }
  }

  validateRetailerReferenceNumber() {
    if (this.hasMerchantReferenceNumberTarget) {
      let result = this.merchantReferenceNumberTarget.value.length > 0
      return result
    } else {
      return true
    }
  }

  validateUnderwritingProgram() {
    if (this.itemTypeCredit() && !this.itemTypeLease()) { return true }
    if (this.invoiceCalculations == undefined) { return false }
    if (LineItemManager.leaseCount() !== 0) {
      return this.underwritingProgramIDTarget.value !== ""
        && this.invoiceCalculations.find(item => item.underwriting_program_id === Number(this.underwritingProgramIDTarget.value)) !== undefined
    }
    return true
  }

  // validate Modal
  validateModalFields() {
    return this.validateProductType() // lease or credit
      && this.validateType()          // merchandise or Additional service
      && this.validateDescription()   // Description
      && this.validatePrice()         // Price
      && this.validateCondition()     // new or used
      && this.validateDamage()        // damage description
  }

  validateProductType() {
    if (this.isSplitTicket()) {
      return this.productTypeLeaseTarget.checked || this.productTypeCreditTarget.checked
    }
    return true
  }

  validateType() {
    if (this.isSplitTicket()) {
      return this.itemTypeMerchandiseTarget.checked || this.itemTypeAdditionalServicesTarget.checked
    }
    return true
  }

  validateDescription() {
    return this.itemDescriptionTarget.value.length > 0
  }

  validatePrice() {
    if (this.itemPriceTarget.value.length === 0) { return false }

    const price = Number(this.itemPriceTarget.value)
    this.displayModalError(price)

    if (this.isSplitTicket()) {
      if (this.productTypeCreditTarget.checked) {
        return this.isAmountValidForCredit(price)
      } else {
        return this.isAmountValidForLease(price)
      }
    }
    if (this.itemTypeCredit()) {
      return this.isAmountValidForCredit(price)
    }
    return this.isAmountValidForLease(price)
  }

  validateCondition() {
    if ( (this.isSplitTicket() && this.productTypeCreditTarget.checked && this.itemTypeAdditionalServicesTarget.checked) || (this.itemTypeCredit() && !this.itemTypeLease()) ) {
      return true
    }
    return this.itemConditionNewTarget.checked || this.itemConditionUsedTarget.checked
  }

  validateDamage() {
    if (this.itemConditionDamagedCheckboxTarget.checked) {
      return this.hash.regex.test(this.itemDamageDescriptionTarget.value) && this.itemDamageDescriptionTarget.value.length > 0
    }
    return true
  }

  /// simple Logic checks
  isAmountValidForCredit(price) {
    return price <= this.creditApprovedAmount
      && price >= 0
  }

  isAmountValidForLease(price) {
    return price <= this.leaseApprovedAmount
      && price >= 0
  }

  itemTypeCredit() {
    return this.creditContract != this.hash.contract_does_not_exist && this.creditContract != ''
  }

  itemTypeLease() {
    return this.leaseContract != this.hash.contract_does_not_exist && this.leaseContract != ''
  }

  isSplitTicket() {
    return this.itemTypeCredit() && this.itemTypeLease()
  }

  /// Handling Errors
  displayModalError(price) {
    // lease view
    if (this.itemTypeLease() && (price < 0 || price > this.leaseApprovedAmount)) {
      this.modalErrorLeaseTarget.classList.remove(this.hash.hidden)
    } else {
      this.modalErrorLeaseTarget.classList.add(this.hash.hidden)
    }
    // credit view
    if (this.itemTypeCredit() && (price < 0 || price > this.creditApprovedAmount)) {
      this.modalErrorCreditTarget.classList.remove(this.hash.hidden)
    } else {
      this.modalErrorCreditTarget.classList.add(this.hash.hidden)
    }
    // split view
    if (this.isSplitTicket()) {
      if (this.productTypeLeaseTarget.checked && (price < 0 || price > this.leaseApprovedAmount)) {
        this.modalErrorLeaseTarget.classList.remove(this.hash.hidden)
      } else {
        this.modalErrorLeaseTarget.classList.add(this.hash.hidden)
      }
      if (this.productTypeCreditTarget.checked && (price < 0 || price > this.creditApprovedAmount)) {
        this.modalErrorCreditTarget.classList.remove(this.hash.hidden)
      } else {
        this.modalErrorCreditTarget.classList.add(this.hash.hidden)
      }
    }
  }

  displayFormError() {
    if (this.itemTypeCredit()) {
      if (LineItemManager.totalCreditPrice() > this.creditApprovedAmount || LineItemManager.totalCreditPrice() < 0) {
        this.formErrorCreditTarget.classList.remove(this.hash.hidden)
      } else {
        this.formErrorCreditTarget.classList.add(this.hash.hidden)
      }
    }
    if (this.itemTypeLease()) {
      if (LineItemManager.totalLeasePrice() > this.leaseApprovedAmount || LineItemManager.totalLeasePrice() < 0) {
        this.formErrorLeaseTarget.classList.remove(this.hash.hidden)
      } else {
        this.formErrorLeaseTarget.classList.add(this.hash.hidden)
      }
      if (LineItemManager.totalLeasePrice() < 100 && LineItemManager.leaseCount() > 0) {
        this.formErrorLeaseMinimumTarget.classList.remove(this.hash.hidden)
      } else {
        this.formErrorLeaseMinimumTarget.classList.add(this.hash.hidden)
      }
    }
  }

  /// Lease Summary box
  // get invoice calculations
  getInvoiceCalculations() {
    const merchandiseTotal = accounting.unformat(this.leaseRetailerInvoiceTotalTarget.value, "", 2)
    const params = new URLSearchParams({
      merchandise_total: merchandiseTotal,
      total_foreign_taxes: 0, // Defaults to 0 Not implemented on V2 contracts ... yet
    }).toString()
    const fetchWith = window.acima.fetchInit({ method: "GET" })
    const url = this.getInvoiceCalculationsUrl(params)

    return fetch(url, fetchWith).then(response => response.json())
  }

  // returns invoice calculations path if invoice is accessed by a merchant or user
  getInvoiceCalculationsUrl(params) {
    if (this.userType === this.hash.merchant) {
      return `/merchants/leases/${this.leaseId}/invoice_calculations?${params}`
    } else {
      return `/users/contracts/${this.leaseId}/invoice_calculations/new?${params}`
    }
  }

  // Initiat setup for Lease Summary
  calculateLeaseSummaryAmounts() {
    this.getInvoiceCalculations()
      .then(function (data) {
        if (!data.success) { return }

        this.invoiceCalculations = data.details

        // group invoiceCalculations by Initial Payment amounts
        this.uniqueIP = {};

        this.invoiceCalculations.forEach((program) => {
          let key = Number(program['initial_payment_amount'])
          if (!this.uniqueIP.hasOwnProperty(key)) {
            this.uniqueIP[key] = []
          }
          this.uniqueIP[key].push(program)
        });

        // make list of Initial Payment amounts
        this.uniqueIP_keys = Object.keys(this.uniqueIP).reverse()

        // sort invoiceCalculations groups by lease_term_months ex:(12, 18, 24)
        this.uniqueIP_keys.forEach((key) => {
          this.uniqueIP[key].sort((a, b) => a['lease_term_months'] - b['lease_term_months'])
        });

        this.additionalDetailsModalUpdater(this.firstInvoiceCaluculation())
        this.leaseSummaryBoxUpdater(this.firstInvoiceCaluculation())
        this.initialPaymentsUpdater()
        this.recurringPaymentsUpdater(this.first_key)
        this.selectFirstIpAndRp()
        this.setUnderwritingCalc()
      }.bind(this))
  }

  // Update Additional Details Modal
  additionalDetailsModalUpdater(details) {
    if (details === null || details === undefined) { return }

    // note: has{targetname}Target returns boolean

    // 90-Day Buyout Amount section
    if (this.hasPreRentBuyoutAmountTarget) {
      this.preRentPurchaseTermTarget.textContent = acima.i18n.t(
        "invoices.calculations.lease_details_preview.buyout_amount",
        { term: details.pre_rent_buyout_days }
      )
      this.preRentBuyoutAmountTarget.textContent = accounting.formatMoney(details.ninety_day_buyout_amount, "$", 2)

      this.remainingBuyoutAmountLabelTarget.textContent = acima.i18n.t(
        "invoices.calculations.lease_details_preview.remaining_buyout_amount",
        { term: details.pre_rent_buyout_days }
      )
      this.remainingPreRentBuyoutAmountTarget.textContent = accounting.formatMoney(details.remaining_ninety_day_buyout_amount, "$", 2)
    }

    // Total of Payments
    this.totalOfPaymentsTarget.textContent = accounting.formatMoney(details.total_of_payments, "$", 2)
    this.totalOfRenewalPaymentsTarget.textContent = accounting.formatMoney(details.total_of_renewal_payments, "$", 2)

    // Renewal Payment Details
    if (this.hasRenewalPaymentAmountTarget) {
      this.renewalPaymentAmountTarget.textContent = accounting.formatMoney(details.renewal_payment_amount, "$", 2)
      this.renewalPaymentFrequencyTarget.textContent = acima.i18n.t(
        `lease_estimates_calculator.pay_frequency.${details.renewal_payment_frequency.toLowerCase()}`
      )
    }
  }

  // Update Lease Summary box
  leaseSummaryBoxUpdater(details) {
    if (details === null || details === undefined) { return }
    this.merchantFeePercentageTarget.textContent = acima.i18n.t(
      "itemized_invoices.lease_summary.retailer_fee_percentage",
      { fee_percentage: parseFloat(this.leaseMerchantFeePercentage * 100) }
    )

    // Note: {target name}+Targets represent multiple targets with the same target name
    // {target name}+Target represent a single target
    this.acimaLeaseAmountTargets.forEach((elem) => {
      elem.textContent = accounting.formatMoney(details.acima_lease_amount, "$", 2)
    })

    this.leaseInitialPaymentPlusTaxTarget.textContent = accounting.formatMoney(details.initial_payment_amount_with_tax, "$", 2)
    this.merchantFeeAmountTarget.textContent = accounting.formatMoney(details.merchant_fee_amount, "$", 2)
  }

  // Update Initial Payment section of Lease Summary
  initialPaymentsUpdater() {
    this.clearInitialPaymentButtons()

    // populate the buttons
    if (this.uniqueIP_keys.length !== 1) {
      this.leaseInitialPaymentChoiceTarget.classList.remove(this.hash.hidden)
      this.uniqueIP_keys.forEach((value, index) => {
        const price_target = `initialPayment${index + 1}Target`
        const fee_target = `initialPaymentFee${index + 1}Target`
        const button = this[price_target].closest('label')

        this[price_target].textContent = accounting.formatMoney(value, "$", 0)
        this[fee_target].textContent = acima.i18n.t(
          "itemized_invoices.lease_summary.initial_payment_percent_retailer_fee",
          { percent: this.uniqueIP[value][0].merchant_fee_percentage }
        )
        button.setAttribute("initial-payment", value)
        button.classList.remove(this.hash.hidden)
      })
    }

    // populate the elements under the button
    this.initialPaymentsDivsUpdater(this.firstInvoiceCaluculation().initial_payment_amount)
  }

  // Update Initial Payment text section
  initialPaymentsDivsUpdater(key) {
    key = Number(key)
    // Note: {target name}+Targets represent multiple targets with the same target name
    // {target name}+Target represent a single target
    // leaseInitialPaymentAmount in both lease summary and Addtional Details modal
    this.leaseInitialPaymentAmountTargets.forEach((elem) => {
      elem.textContent = accounting.formatMoney(this.uniqueIP[key][0]["initial_payment_amount"], "$", 2)
      elem.dataset.content = this.uniqueIP[key][0].initial_payment_amount
    })
    this.leaseProcessingFeeTarget.textContent = accounting.formatMoney(this.uniqueIP[key][0].processing_fee, "$", 2)
  }

  // Update Recurring Payment section of Lease Summary
  recurringPaymentsUpdater(key) {
    this.clearRecurringPaymentButtons()
    this.leaseRecurringPaymentChoiceTarget.classList.add(this.hash.hidden)
    this.leaseRecurringPaymentNoChoiceTarget.classList.add(this.hash.hidden)

    // if only one recurring payment option
    if (this.uniqueIP[key].length === 1) {
      this.leaseRecurringPaymentTarget.textContent = accounting.formatMoney(this.uniqueIP[key][0].renewal_payment_amount, "$", 2)
      this.leaseRecurringPaymentNoChoiceTarget.classList.remove(this.hash.hidden)
      return
    }
    // if multiple recurring payment option
    this.uniqueIP[key].forEach((value, index) => {
      const price_target = `recurringPayment${index + 1}Target`
      const period_target = `recurringPaymentPeriod${index + 1}Target`
      const button = this[price_target].closest('label')

      this[price_target].textContent = accounting.formatMoney(value.renewal_payment_amount, "$", 2)
      this[period_target].textContent = acima.i18n.t(
        "invoices.programs.term_months",
        { term_months: value.lease_term_months }
      )

      button.setAttribute("underwriting-program-id", value["underwriting_program_id"])
      button.classList.remove(this.hash.hidden)
    })

    this.leaseRecurringPaymentChoiceTarget.classList.remove(this.hash.hidden)
  }

  // select intial payment, update recurring payment buttons, and set UW id that corresponds with configuration
  selectInitialPayment(event) {
    this.removeSelectedClass("selected-initial-payment")
    event.currentTarget.classList.add("selected-initial-payment")
    this.initialPaymentsDivsUpdater(Number(event.currentTarget.getAttribute("initial-payment")))
    this.recurringPaymentsUpdater(Number(event.currentTarget.getAttribute("initial-payment")))

    const first_recurring_payment_button = this.recurringPayment1Target
    if (!first_recurring_payment_button.classList.contains(this.hash.hidden)) {
      const first_recurring_payment_button = this.recurringPayment1Target.closest('label')
      first_recurring_payment_button.classList.add("selected-recurring-payment")
    }

    this.setUnderWritingValue()
    this.enableSaveForm()
  }

  // select recurring payment and set UW id that corresponds with configuration
  selectReccurringPayment(event) {
    const underwriting_program_id = event.currentTarget.getAttribute("underwriting-program-id")
    this.removeSelectedClass("selected-recurring-payment")
    event.currentTarget.classList.add("selected-recurring-payment")
    this.setUnderWritingValue()
    this.leaseUpdater(underwriting_program_id)
    this.enableSaveForm()
  }

  // update Lease components based on UW program
  leaseUpdater(underwriting_program_id) {
    const details = this.invoiceCalculations.find(item => item.underwriting_program_id === Number(underwriting_program_id))
    this.leaseSummaryBoxUpdater(details)
    this.additionalDetailsModalUpdater(details)
  }

  // Clear all and hide initial payment buttons
  clearInitialPaymentButtons() {
    this.clearButtons("initialPayment", "initialPaymentFee")
    this.removeSelectedClass("selected-initial-payment")
  }

  // Clear all and hide recurring payment buttons
  clearRecurringPaymentButtons() {
    this.clearButtons("recurringPayment", "recurringPaymentPeriod")
    this.removeSelectedClass("selected-recurring-payment")
  }

  clearButtons(main_target, sub_target) {
    for (var i = 0; i < 3; i++) {
      const main_text = `${main_target}${i + 1}Target`
      const sub_text = `${sub_target}${i + 1}Target`
      const button = this[main_text].closest('label')

      button.classList.add(this.hash.hidden)
      this[main_text].innerText = ""
      this[sub_text].innerText = ""
    }
  }

  selectFirstIpAndRp() {
    const first_initial_payment_button = this.initialPayment1Target
    if (!first_initial_payment_button.classList.contains(this.hash.hidden)) {
      const first_initial_payment_button = this.initialPayment1Target.closest('label')
      first_initial_payment_button.classList.add("selected-initial-payment")
    }

    const first_recurring_payment_button = this.recurringPayment1Target
    if (!first_recurring_payment_button.classList.contains(this.hash.hidden)) {
      const first_recurring_payment_button = this.recurringPayment1Target.closest('label')
      first_recurring_payment_button.classList.add("selected-recurring-payment")
    }

    this.setUnderWritingValue()
  }

  // set Underwriting value to hidden field
  setUnderWritingValue() {
    this.underwritingProgramIDTarget.value = ""

    // if no choice summary box is displayed
    const no_choice = this.leaseInitialPaymentChoiceTarget.classList.contains(this.hash.hidden)
      && !this.leaseRecurringPaymentNoChoiceTarget.classList.contains(this.hash.hidden)
      && this.leaseRecurringPaymentChoiceTarget.classList.contains(this.hash.hidden)

    if (no_choice) {
      this.underwritingProgramIDTarget.value = this.firstInvoiceCaluculation()["underwriting_program_id"]
      return
    }

    // if IP summary box is displayed
    const ip_summary_box = !this.leaseInitialPaymentChoiceTarget.classList.contains(this.hash.hidden)
      && !this.leaseRecurringPaymentNoChoiceTarget.classList.contains(this.hash.hidden)
      && this.leaseRecurringPaymentChoiceTarget.classList.contains(this.hash.hidden)

    const initial_button = document.querySelector(".selected-initial-payment")
    if (ip_summary_box) {
      const initial_payment_amount = Number(initial_button.getAttribute("initial-payment"))
      this.underwritingProgramIDTarget.value = this.uniqueIP[initial_payment_amount][0]["underwriting_program_id"]
      return
    }

    // if RP or both IP and RP sumary boxes displayed
    const recurring_button = document.querySelector(".selected-recurring-payment")
    this.underwritingProgramIDTarget.value = Number(recurring_button.getAttribute("underwriting-program-id"))
  }

  /// helper functions
  formatPrice(value) {
    return (Math.round(value * 100) / 100).toFixed(2)
  }

  removeSelectedClass(class_name) {
    const element = document.querySelector("." + class_name)
    if (element != null) {
      element.classList.remove(class_name)
    }
  }

  firstInvoiceCaluculation() {
    // Note first initial payment key is the highest number
    this.first_key = Number(this.uniqueIP_keys.reduce((prev, curr) => (parseFloat(curr) > parseFloat(prev) ? curr : prev)))
    return this.uniqueIP[this.first_key][0]
  }

  isItemConditionOptional() {
    return this.isSplitTicket() && this.itemTypeAdditionalServicesTarget.checked && this.productTypeCreditTarget.checked
  }
}
